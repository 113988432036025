<template>
  <div class="dbox loading-box" v-loading="loading" element-loading-text="loading..."
    element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0)">
    <!-- <div class="left">
      <el-container>
        <CommonAside />
      </el-container>
    </div> -->
    <div class="right">
      <!-- <TopRes></TopRes> -->
      <div style="height:250px;">
        <el-carousel :interval="4000" height="250px">
          <el-carousel-item v-for="item in Math.ceil(imgList.length / 3)" :key="item">
            <div class="content">
              <div class="content-item" v-for="(item, index) in imgList.slice((item - 1) * 3, item * 3)" :key="index">
                <img :src="item.img">
              </div>
            </div>
          </el-carousel-item>
        </el-carousel>
      </div>
      <!-- <div>
      <img
        src="../../assets/bodyHomeGroup.png"
        style="width: 80%; height: 200px; margin-top: 60px; margin-left: 18%"
        alt=""
      />
    </div> -->
      <div class="zy_dh">
        <div class="zy_dh1" v-for="(item, index) in gamelist" @click="ontabtap(index)" :key="index"
          :class="tabIndex == index ? 'tab_active' : 'tab_noactive'">
          <img src="https://pc.dqbet7.com/assets/hoticon.png" v-if="item.type == 'hots'" />
          <img src="https://pc.dqbet7.com/assets/tadaicon.png" v-if="item.type == 'tada'" />
          <img src="https://pc.dqbet7.com/assets/vivoicon.png" v-if="item.type == 'vivo'" />
          <img src="https://pc.dqbet7.com/assets/pgicon.png" v-if="item.type == 'pgs'" />
          <img src="https://pc.dqbet7.com/assets/ppicon.png" v-if="item.type == 'pps'" />
          <img src="https://pc.dqbet7.com/assets/cq9icon.png" v-if="item.type == 'cq9'" />

          <div style="color: #fff;">{{ item.title }}</div>
        </div>
      </div>
      <div class="index_body" v-for="(i, n) in gamelist" :key="n" v-show="n == tabIndex">
        <div class="index_body_n" v-for="(it, m) in i.data" :key="m">
          <div class="zy_thi">
            <!-- <img :src="it.icon" /> -->
            <div style="color: #fff; line-height: 40px">{{ it.title }}</div>
            <span class="more-btn" @click="toMorePage(it)">{{ $t('more_info') }}</span>
          </div>
          <div class="img_games">
            <div class="zy_th" v-for="(t, k) in it.res" :key="k" @click="gameson(it.res[k])">
              <!-- <el-image :src="t.icon" lazy></el-image> -->
              <!-- <img :src="t.icon" /> -->
              <img v-lazy="t.icon" />
            </div>
          </div>
        </div>
      </div>

      <div class="index_body_n" v-if="tabIndex == 0">
        <div class="zy_thi">
          <div style="color: #fff; line-height: 40px">Pocket Games Soft</div>
          <span class="more-btn" @click="toMorePage(tomorePg)">{{ $t('Ver Tudo') }}</span>
        </div>
        <div class="img_games">
          <div class="zy_th" v-for="(t, k) in pggameList" :key="k" @click="gameson(t)">
            <img v-lazy="t.icon" />
          </div>
        </div>
      </div>

      <div class="index_body_n" v-if="tabIndex == 0">
        <div class="zy_thi">
          <div style="color: #fff; line-height: 40px">Pragmatic Play</div>
          <span class="more-btn" @click="toMorePage(tomorePp)">{{ $t('Ver Tudo') }}</span>
        </div>
        <div class="img_games">
          <div class="zy_th" v-for="(t, k) in ppgameList" :key="k" @click="gameson(t)">
            <img v-lazy="t.icon" />
          </div>
        </div>
      </div>


      <div class="tab">
        <el-container>
          <tabBar></tabBar>
        </el-container>
      </div>
    </div>
  </div>
</template>
  
<script>
// import CommonAside from "../../components/CommonAside";
// import TopRes from "../../components/TopRes.vue";
import tabBar from '../../components/tabBar.vue';
import { banner, index, games } from "../../api/index.js";
export default {
  components: {
    // CommonAside,
    // TopRes,
    tabBar,
  },

  data() {
    return {
      imgList: [
        {id:1,img:"https://pc.dqbet7.com/assets/bannera.png"},
        {id:2,img:"https://pc.dqbet7.com/assets/bannerb.png"},
        {id:3,img:"https://pc.dqbet7.com/assets/bannerc.png"},

      ],
      gamelist: [],
      pggameList: [],
      ppgameList: [],
      tomorePg: {
        title: "PG",
        platform: "PGS",
        // icon:'https://img.dqbet7.com/tabicon/tab_1.png'
      },
      tomorePp: {
        title: "PP",
        platform: "PP",
        // icon:'https://img.dqbet7.com/tabicon/tab_1.png'
      },
      tabIndex: 0,
      tabIndex1: 0,
      paydata: {
        act: 'game_url',
        game_code: '',
      },
      loading: false,
    };
  },

  methods: {
    bannelist() {
      banner().then((res) => {
       
        // this.imgList = res.data.banners
        console.log(this.imgList);
      })
    },

    games1() {
      index().then((res) => {
        console.log(res);
        this.gamelist = res.data.category_icon
        this.gamelist.push({ data: [{ icon: "../../assets/pgicon.png", res: res.data.gameList.pgList, title: "PGS" }], icon: "https://pc.dqbet7.com/assets/pgicon.png", sort: 8, status: 8, title: "PG", type: "pgs", id: 8, })
        this.gamelist.push({ data: [{ icon: "../../assets/ppicon.png", res: res.data.gameList.ppList, title: "PPS" }], icon: "https://pc.dqbet7.com/assets/ppicon.png", sort: 6, status: 6, title: "PP", type: "pps", id: 5, })
        this.pggameList = res.data.gameList.pgList
        this.ppgameList = res.data.gameList.ppList
      })
    },

    ontabtap(index) {
      this.tabIndex = index
      this.tabIndex1 = index
    },

    gameson(k) {
      this.loading = true;
      this.paydata.game_code = k.game_code
      console.log(this.paydata, 991)
      if (k.platform == "TADA") {
        this.paydata.act = "tada_game_url"
      } else if (k.platform == 'PGSPRO') {
        this.paydata.act = "pgpro_oh_game_url"
      } else {
        this.paydata.act = "game_url"
      }

      this.$router.push({
          path:'/WebView',
          query:{game:JSON.stringify(this.paydata)}
        })
      // games(this.paydata).then((res) => {
      //   this.loading = false;
      //   let url = res.data.url
      //   window.location.href = url
      // })
    },

    toMorePage(item) {
      // console.log('item==>', item);
      if (item.title == 'Slots') {
        this.ontabtap(1);
      } else {
        if (item.title == "PG" || item.title == "PP") {
          const params = {
            title: item.title,
            icon: item.icon,
            platform: item.platform,
          };
          this.$router.push({
            path: '/MorePage',
            query: { p: JSON.stringify(params) },
          });
        }
        const params = {
          title: item.title,
          icon: item.icon,
          platform: item.res[0].platform,
        };
        // this.$router.push({
        //   name: 'MorePage', 
        //   params
        // });
        this.$router.push({
          path: '/MorePage',
          query: { p: JSON.stringify(params) },
        });
      }
    },
  },

  mounted() {
    this.bannelist()
    this.games1()
  },
};
</script>
  
<style scoped>
* {
  margin: 0;
  padding: 0;
}

.el-carousel ::v-deep .el-carousel__arrow {
  background-color: rgba(100, 100, 100, 0.8);
  transform: scale(1.2);
}

.tab ::v-deep .bar-box {
  width: 90%;
}

.more-btn {
  width: 52px;
  height: 26px;
  line-height: 26px;
  border-radius: 15px;
  /* background: linear-gradient(180deg, #6892A4 0%, #416375 100%); */
  color: #fff;
  font-size: 12px;
  text-align: center;
  position: absolute;
  right: 20px;
  cursor: pointer;
}

/* .loading-box ::v-deep .el-loading-mask {
  background-color: transparent;
} */

.loading-box ::v-deep .el-icon-loading,
.loading-box ::v-deep .el-loading-text {
  color: #fff;
  font-size: 28px;
}

.img_games {
  /* width: 90%; */
  /* margin: 10px auto 0 auto; */
  margin-top: 10px;
  display: flex;
  flex-wrap: wrap;
  /* clear: both; */
  color: #fff
}

.zy_th img {
  /* width: 190px;
  height: 250px;
  margin-right: 10px */
}

.zy_thi {
  /* width: 90%; */
  height: 40px;
  /* margin: 30px auto 0 auto; */
  margin-top: 30px;
  margin-left: 15px;
  display: flex;
  /* clear: both; */
  align-items: center;
  position: relative;
}

.zy_dh {
  /* width: 80%; */
  /* height: 40px; */
  /* margin: 50px 0 0 80px; */
  margin-top: 50px;
  display: flex;
  justify-content: flex-start;
  gap: 1.5%;
}

.zy_dh1 {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 5px;
  /* width: 127px; */
  width: 142px;
  height: 48px;
  background-color: rgba(255, 255, 255, 0.1);
  border-radius: 10px;
  text-align: center;
  /* margin-right: 20px; */
  line-height: 48px;
  cursor: pointer;
}

.zy_dh1 img {
  width: 30px;
  height: 30px;
  /* margin: 10px 5px 0 10px; */
  text-align: center;
}

.lunb {
  display: flex;
  width: 315px;
  height: 88px
}

.el-carousel__item {
  display: flex;
  justify-content: space-between !important;
  display: inline-flex !important;
}

.el-carousel__item img {
  width: 30%;
  text-align: center;
}

.dbox {
  width: 100%;
  height: 100%;
  display: flex;
  background: #000;
}

/* .left{
  width:220px;
  height:100%
} */

.right {
  /* flex:1;
  background:#000;
  height:100%; */
  overflow-y: auto;
  padding: 0 40px;
  padding-bottom: 100px;
  background-size: 100%;
  background-image: url(../../assets/share-cover.png);
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center center;
}

.right::-webkit-scrollbar {
  width: 10px;
  height: 10px;
  background-color: #000;
  border-radius: 5px;
}

.right::-webkit-scrollbar-track {
  background-color: #000;
  border-radius: 5px;
}

.content {
  width: 100%;
  height: 250px;
  display: flex;
  justify-content: space-between
}

.content-item {
  width: 32%
}

.content-item img {
  width: 100%;
  height: 100%
}

.tab_active {
  border-radius: 27px;
  background:linear-gradient( 90deg, #FBE4BA 0%, #EC6AF1 100%);

}
.tab_noactive{
  background: #000;
  opacity: 0.5;
  color: #fff;
}

.index_body {
  /* width: 100%; */
  /* clear: both */
}

.zy_thi img {
  width: 35px;
  height: 35px;
  margin-right: 10px;
}

.img_games .zy_th {
  /* width: 160px !important;
  height: 240px !important;
  margin-right: 10px;
  position: relative; */
  width: 14.11%;
  padding: 10px;
  box-sizing: border-box;
}

.img_games .zy_th img {
  /* width: 160px !important;
  height: 240px !important;
  margin-right: 10px; */
  width: 100%;
  box-sizing: border-box;
  border-radius: 24px;
}

.img_games .zy_th img:hover {
  border-radius: 12px;
  border: 1px solid #ffc12a;
  transition: all .1s;
}

.index_body_n {
  /* width: 90%; */
}
</style>
  